import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { GlassCard, H1, H2 } from '../../../components'
import Background from '../../../images/lore-background.svg'
import Two from '../../../images/lore/1.jpeg'
import Three from '../../../images/lore/2.png'
import Beatrice from '../../../images/lore/3.jpg'
import Five from '../../../images/lore/4.jpeg'
import One from '../../../images/lore/sun-explode.png'
import Weapon from '../../../images/lore/weapon.jpg'

export const Lore: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Section>
      <ShortWidth>
        <Positioned1>
          <LeftH1>{t('hostileDawns')}</LeftH1>
          <H2>{t('dangerEnv')}</H2>

          <StyledP>
            {t('fiveYears1')}
            <br />
            <br />
            {t('fiveYears2')}
            <br />
            <br />
            {t('fiveYears3')}
          </StyledP>
        </Positioned1>

        <Positioned2>
          <StyledImg background={One}></StyledImg>
        </Positioned2>

        <Positioned3>
          <ImgWrapper>
            <StyledImg background={Two}></StyledImg>
          </ImgWrapper>
        </Positioned3>

        <Positioned4>
          <H2>{t('change')}</H2>

          <StyledP>
            {t('change1')} {t('change2')}
            <br />
            <br />
            {t('change3')}
            <br />
            <br />
            {t('change4')}
            <br />
            <br />
            {t('change5')}
            <br />
            <br />
            {t('change6')}
          </StyledP>
        </Positioned4>

        <Positioned5>
          <StyledImg background={Three}></StyledImg>
        </Positioned5>

        <Positioned6>
          <H2>{t('ashes')}</H2>
          <StyledP>
            {t('ashes1')}
            <br />
            <br />
            {t('ashes2')}
          </StyledP>
        </Positioned6>

        <Positioned7>
          <StyledImg background={Beatrice}></StyledImg>
        </Positioned7>

        <Positioned8>
          <H2>{t('calledCollectors')}</H2>
        </Positioned8>

        <Positioned9>
          <StyledImg background={Weapon}></StyledImg>
        </Positioned9>

        <Positioned10>
          <H2>{t('seekingShadow')}</H2>
          <StyledP>
            {t('shadow1')}
            <br />
            <br />
            {t('shadow2')}
            <br />
            <br />
            {t('shadow3')}
            <br />
            <br />
            {t('shadow4')}
          </StyledP>
        </Positioned10>

        <Positioned11>
          <StyledImg background={Five} />
        </Positioned11>
      </ShortWidth>
    </Section>
  )
}

export default Lore
const LeftH1 = styled(H1)`
  text-align: left;
  margin-bottom: 0;
`

const Positioned1 = styled(GlassCard)`
  grid-area: first;
  > h1 {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  > h2 {
    margin-top: 0;

    margin-bottom: 1rem;
  }
`

const Positioned2 = styled(GlassCard)`
  @media (min-width: 200px) {
    height: 20rem;
  }
  @media (min-width: 750px) {
    height: auto;
  }
  grid-area: second;
`

const Positioned3 = styled(GlassCard)`
  grid-area: third;
  height: 50rem;
`
const Positioned4 = styled(GlassCard)`
  grid-area: fourth;

  > h2 {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  @media (min-width: 200px) {
    columns: 1;
  }

  @media (min-width: 1200px) {
    columns: 2;
  }
`

const Positioned5 = styled(GlassCard)`
  @media (min-width: 200px) {
    height: 20rem;
  }
  @media (min-width: 750px) {
    height: auto;
  }
  grid-area: five;
`

const Positioned6 = styled(GlassCard)`
  grid-area: six;

  > h2 {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`

const Positioned7 = styled(GlassCard)`
  grid-area: seven;
  height: 50rem;
`

const Positioned8 = styled(GlassCard)`
  grid-area: eight;
  text-align: center;

  > h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const Positioned9 = styled(GlassCard)`
  @media (min-width: 200px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: inline-block;
    grid-area: weapon;
  }
`

const Positioned10 = styled(GlassCard)`
  grid-area: ten;

  > h2 {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`
const Positioned11 = styled(GlassCard)`
  grid-area: eleven;
  height: 55rem;
`

interface Img {
  background: any
}

const StyledImg = styled.div<Img>`
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const StyledP = styled.p`
  line-height: 175%;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;

  &:first-letter {
    color: white;
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
  }
`

const ShortWidth = styled.article`
  padding-bottom: 5rem;

  @media (min-width: 200px) {
    margin-top: 3rem;
    width: 100%;
    row-gap: 2rem;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    grid-template-areas:
      'first first '
      'second second'
      'third third '
      'fourth fourth '
      'five five  '
      'six six'
      'seven seven '
      'eight eight '
      'ten ten '
      'eleven eleven ';
  }
  @media (min-width: 750px) {
    display: grid;

    width: 85%;
    padding-top: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'first first second second '
      'first first second second'
      'third third third third'
      'fourth fourth fourth fourth'
      'five five six six '
      'seven seven seven seven'
      'eight eight eight eight'
      'ten ten ten ten'
      'eleven eleven eleven eleven';
    column-gap: 1rem;
    row-gap: 1rem;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    grid-template-areas:
      'first first second second '
      'first first second second'
      'third third third third'
      'fourth fourth fourth fourth'
      'five five six six '
      'seven seven seven seven'
      'eight eight eight eight'
      'weapon ten ten ten'
      'weapon ten ten ten '
      'eleven eleven eleven eleven';
  }
`

const Section = styled.section`
  width: 100vw;
  padding-top: 5rem;
  background: url(${Background}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
`
